import { createRouter, createWebHistory } from 'vue-router'
import Start from '@/views/Start.vue'
import Questionnaire from '@/views/Questionnaire.vue'

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start
  },
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: Questionnaire,
    children: [
      {path: '', redirect: '/questionnaire/intro'},
      {path: 'intro', component: require('@/views/questionnaire/Intro.vue').default},
      {path: 'sex', component: require('@/views/questionnaire/Sex.vue').default},
      {path: 'birthday', component: require('@/views/questionnaire/Birthday.vue').default},
      {path: 'trait/:id(\\d+)', component: require('@/views/questionnaire/Trait.vue').default, props: true},
      {path: 'subject', component: require('@/views/questionnaire/Subject.vue').default},
      {path: 'spirituality', component: require('@/views/questionnaire/Spirituality.vue').default},
      {path: 'study', component: require('@/views/questionnaire/Study.vue').default},
      {path: 'result', component: require('@/views/questionnaire/Result.vue').default},
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: require('@/views/About.vue').default
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return {
      top: 0,
      behavior: 'smooth',
    }
  }
  
  
})

export default router
