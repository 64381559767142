<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t("questionnaire.sex_title") }}
    .box
      .block {{ $t("questionnaire.sex_message") }}
      .columns.is-centered
        .column.is-one-quarter
          .button.is-fullwidth(:class="{ 'is-primary': isSex('diverse') }", @click="setSex('diverse')") {{ $t("questionnaire.diverse") }}
        .column.is-one-quarter
          .button.is-fullwidth(:class="{ 'is-primary': isSex('male') }", @click="setSex('male')") {{ $t("questionnaire.male") }}
        .column.is-one-quarter
          .button.is-fullwidth(:class="{ 'is-primary': isSex('female') }", @click="setSex('female')") {{ $t("questionnaire.female") }}

    Navigation(backward="/questionnaire/intro", :forward="forward")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'
import { store } from './store'

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      answers: store.answers
    }
  },
  computed: {
    forward() {
      if (this.answers["sex"]) {
        return "/questionnaire/birthday"
      } else {
        return ""
      }
    }
  },
  methods: {
    isSex(sex) {
      console.log("isSex", sex, this.answers["sex"] == sex)
      return this.answers["sex"] == sex
    },
    setSex(sex) {
      console.log("setSex", sex)
      store.setAnswer('sex', sex)
    }
  }

}
</script>
