<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t('questionnaire.results') }}
    .box.chart
      h6.title.is-6 {{ $t('questionnaire.sign_matches') }}
      .columns.is-size-7.is-vcentered.is-multiline.is-gapless
        template(v-for="no in zodiac_sign.length")
          .column.is-2.has-text-centered.mb-1(:class="{ 'has-text-weight-bold': is_birthday_sign_no(no) }") {{ $t('zodiac_sign.' + zodiac_sign[no - 1]) }}
          .column.is-10.mb-1
            progress.progress.is-medium.is-primary(:value="trait_value_no(no)", max="4") 15

      h6.title.is-6 {{ $t('questionnaire.element_matches') }}
      .columns.is-size-7.is-vcentered.is-multiline.is-gapless
        template(v-for="no in zodiac_element.length")
          .column.is-2.has-text-centered.mb-1(:class="{ 'has-text-weight-bold': is_birthday_element_no(no) }")  {{ $t('zodiac_element.' + zodiac_element[no - 1]) }}
          .column.is-10.mb-1
            progress.progress.is-medium.is-primary(:value="trait_element_no(no)", max="12") 15

    Navigation(backward="", forward="")

</template>

<script>
import Navigation from './Navigation'
import { store } from './store'
import { zodiac_sign, zodiac_sign_index, zodiac_element, zodiac_element_index } from '@/zodiac'

export default {
  components: {
    Navigation,
  },
  created() {
    store.maybePostAnswers()
  },
  data() {
    return {
      answers: store.answers,
      zodiac_sign,
      zodiac_element,
    }
  },
  computed: {
  },
  methods: {
    is_birthday_sign_no(no) {
      return zodiac_sign_index(this.answers.day, this.answers.month) == no - 1
    },
    is_birthday_element_no(no) {
      return zodiac_element_index(this.answers.day, this.answers.month) == no - 1
    },
    trait_value_no(no) {
      const name = zodiac_sign[no - 1]
      var value = 0
      for (const trait in this.answers.traits) {
        if (this.answers.traits[trait].sign == name) {
          value += this.answers.traits[trait].value
        }
      }
      console.log(no, name, value)
      return value
    }, 
    trait_element_no(no) {
      const idx = no - 1
      var value = 0
      for (const trait in this.answers.traits) {
        const sidx = zodiac_sign.indexOf(this.answers.traits[trait].sign) % 4
        console.log(idx, sidx, this.answers.traits[trait].sign)
        if (sidx == idx) {
          value += this.answers.traits[trait].value
        }
      }
      console.log(no, idx, value)
      return value
    } 
    
  }

}
</script>
<style lang="scss">
.chart {
  .progress {
  }
}
</style>