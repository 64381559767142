<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t("questionnaire.subject_title") }}
    .box
      .block {{ $t("questionnaire.subject_message") }}
      .columns.is-centered
        .column.is-one-quarter
          .button.is-fullwidth(:class="{ 'is-primary': isSubject('self') }", @click="setSubject('self')") {{ $t("questionnaire.subject_self") }}
        .column.is-one-quarter
          .button.is-fullwidth(:class="{ 'is-primary': isSubject('friend') }", @click="setSubject('friend')") {{ $t("questionnaire.subject_friend") }}

    Navigation(backward="/questionnaire/trait/24", :forward="forward")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'
import { store } from './store'

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      answers: store.answers
    }
  },
  computed: {
    forward() {
      if (this.answers["subject"]) {
        return "/questionnaire/spirituality"
      } else {
        return ""
      }
    }
  },
  methods: {
    isSubject(subject) {
      return this.answers["subject"] == subject
    },
    setSubject(subject) {
      store.setAnswer('subject', subject)
    }
  }

}
</script>
