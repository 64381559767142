<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t('questionnaire.trait') }}  {{ id }} / {{ traits.length }}
    .box
      .block
        | {{ $t('zodiac_sign_traits.' + traits[trait_index].sign + (traits[trait_index].positive ? '_positive' : '_negative')) }}
      h6.title.is-6 {{ $t('questionnaire.trait_matching') }}
      .columns
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isTrait(trait_index, 0) }",
            @click="setTrait(trait_index, 0)"
          ) {{ $t('questionnaire.no_match') }}
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isTrait(trait_index, 1) }",
            @click="setTrait(trait_index, 1)"
          ) {{ $t('questionnaire.partly_match') }}
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isTrait(trait_index, 2) }",
            @click="setTrait(trait_index, 2)"
          ) {{ $t('questionnaire.full_match') }}
    Navigation(:backward="backward", :forward="forward")
</template>

<style lang="scss">
</style>

<script>
import { reactive } from "vue";
import Navigation from "./Navigation";
import { store } from "./store";
import { zodiac_sign_traits } from '@/zodiac'

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}



export default {
  components: {
    Navigation,
  },
  props: ["id"],
  created() {
    if (parseInt(this.id) > this.traits.length) {
      this.$router.replace("/questionnaire/result");
    }
    if (!this.answers["sex"]) {
      this.$router.replace("/questionnaire/sex");
    }
  },
  computed: {
    forward() {
      if (this.isValid()) {
        if (parseInt(this.id) >= this.traits.length) {
          return "/questionnaire/subject";
        } else {
          return "/questionnaire/trait/" + (parseInt(this.id) + 1);
        }
      } else {
        return "";
      }
    },
    backward() {
      if (this.id > 1) {
        return "/questionnaire/trait/" + (parseInt(this.id) - 1);
      } else {
        return "/questionnaire/birthday";
      }
    },
    trait_index() {
      return this.order[this.id - 1]
    }
  },
  methods: {
    isValid() {
      return this.answers.traits && this.trait_index in this.answers.traits;
    },
    isTrait(idx, value) {
      return this.isValid() && this.answers.traits[idx].value == value;
    },
    setTrait(idx, value) {
      console.log(idx, value)
      var _traits = this.answers.traits ?? reactive({});
      _traits[idx] = {value, sign: zodiac_sign_traits[idx].sign, positive: zodiac_sign_traits[idx].positive, ts: Date.now()}
      store.setAnswer("traits", _traits);
    },
  
  },
  data() {
    return {
      answers: store.answers,
      order: shuffle([...Array(zodiac_sign_traits.length).keys()]),
      traits: zodiac_sign_traits
    };
  },
};
</script>
<style scoped>
</style>