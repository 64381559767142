export function zodiac_sign_index(day, month) {
    //bound is zero indexed and returns the day of month where the boundary occurs
    //ie. bound[0] = 20; means January 20th is the boundary for a zodiac sign
    var bound = [20, 19, 20, 20, 20, 21, 22, 22, 21, 22, 21, 21];
    var monthIndex = month - 1; //so we can use zero indexed arrays
    var signMonthIndex = 0
    if (day <= bound[monthIndex]) { //it's start of month -- before or equal to bound date
        signMonthIndex = monthIndex;
    } else { //it must be later than bound, we use the next month's startMonth
        signMonthIndex = (monthIndex + 1) % 12; //mod 12 to loop around to January index.
    }
    return signMonthIndex //return the Zodiac sign of start Of that month.

}

export function zodiac_element_index(day, month) {
    return zodiac_sign_index(day, month) % 4
}

export const zodiac_sign = [
    "capricorn", "aquarius", "pisces", "aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius" ]

export const zodiac_sign_element = [
        "earth", "air", "water", "fire", "earth", "air", "water", "fire", "earth", "air", "water", "fire" ]

export const zodiac_element = ["earth", "air", "water", "fire"]

export const zodiac_sign_traits = [
    {
        sign: `aries`,
        positive: true
    },
    {
        sign: `aries`,
        positive: false
    },
    {
        sign: `taurus`,
        positive: true
    },
    {
        sign: `taurus`,
        positive: false
    },
    {
        sign: `gemini`,
        positive: true
    },
    {
        sign: `gemini`,
        positive: false
    },
    {
        sign: `cancer`,
        positive: true
    },
    {
        sign: `cancer`,
        positive: false
    },
    {
        sign: `leo`,
        positive: true
    },
    {
        sign: `leo`,
        positive: false
    },
    {
        sign: `virgo`,
        positive: true
    },
    {
        sign: `virgo`,
        positive: false
    },
    {
        sign: `libra`,
        positive: true
    },
    {
        sign: `libra`,
        positive: false
    },
    {
        sign: `scorpio`,
        positive: true
    },
    {
        sign: `scorpio`,
        positive: false
    },
    {
        sign: `sagittarius`,
        positive: true
    },
    {
        sign: `sagittarius`,
        positive: false
    },
    {
        sign: `capricorn`,
        positive: true
    },
    {
        sign: `capricorn`,
        positive: false
    },
    {
        sign: `aquarius`,
        positive: true
    },
    {
        sign: `aquarius`,
        positive: false
    },
    {
        sign: `pisces`,
        positive: true
    },
    {
        sign: `pisces`,
        positive: false
    },
];

