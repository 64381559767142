<template lang="pug">
nav.navbar.is-dark(role='navigation' aria-label='main navigation')
  .container
    .navbar-brand
      router-link.navbar-item(to="/")
        | StarScign
      a.navbar-burger(role='button' aria-label='menu' aria-expanded='false' data-target='navbarBasicExample', @click="toggle")
        span(aria-hidden='true')
        span(aria-hidden='true')
        span(aria-hidden='true')
    #navbarBasicExample.navbar-menu(:class="{ 'is-active': activate }")
      .navbar-start
        router-link.navbar-item(to="/")
          | {{ $t("navigation.start") }}
        router-link.navbar-item(to="/questionnaire")
          | {{ $t("navigation.questionnaire") }}
        router-link.navbar-item(to="/analysis")
          | {{ $t("navigation.analysis") }}
        router-link.navbar-item(to="/about")
          | {{ $t("navigation.about") }}
      .navbar-end
        .navbar-item
          .control.has-icons-left
            .select.is-small
              select(v-model="$i18n.locale")
                option(v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale") {{ locale }}
            span.icon.is-small.is-left.has-text-info
              i.fas.fa-globe
</template>

<script>
export default {
  name: 'main navigation',
  data () {
    return {
      activate: false
    }
  },
  methods: {
    toggle () {
      this.activate = !this.activate
      return false
    }
  },
  watch: {
    '$route' () {
      this.activate = false
    },
    '$i18n.locale' () {
      this.activate = false
    }
  }
}
</script>