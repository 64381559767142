<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t("questionnaire.study_title") }}
    .box
      .block {{ $t("questionnaire.study_message") }}
      .columns
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isStudy(true) }",
            @click="setStudy(true)"
          ) {{ $t('questionnaire.study_yes') }}
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isStudy(false) }",
            @click="setStudy(false)"
          ) {{ $t('questionnaire.study_no') }}

    Navigation(backward="/questionnaire/spirituality", :forward="forward")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'
import { store } from './store'

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      answers: store.answers
    }
  },
  computed: {
    forward() {
      if (this.answers["study"] !== undefined) {
        return "/questionnaire/result"
      } else {
        return ""
      }
    }
  },
  methods: {
    isStudy(study) {
      return this.answers["study"] == study
    },
    setStudy(study) {
      store.setAnswer('study', study)
    }
  }

}
</script>
