<template lang="pug">
.block
    router-link(:to="backward", append=true v-if="backward")
      .button {{ $t("questionnaire.backward") }}
    .button(v-else, disabled) {{ $t("questionnaire.backward") }}
    router-link(:to="forward", append=true, v-if="forward")
      .button.is-primary.is-pulled-right {{ $t("questionnaire.forward") }}
    .button.is-primary.is-pulled-right(v-else, disabled) {{ $t("questionnaire.forward") }}
</template>

<style lang="scss">
</style>

<script>
export default {
    props: ['forward', 'backward']
}
</script>
