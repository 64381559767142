<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t("questionnaire.spirituality_title") }}
    .box
      .block {{ $t("questionnaire.spirituality_message") }}
      .columns
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isSpirituality(0) }",
            @click="setSpirituality(0)"
          ) {{ $t('questionnaire.no_match') }}
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isSpirituality(1) }",
            @click="setSpirituality(1)"
          ) {{ $t('questionnaire.partly_match') }}
        .column
          .button.is-fullwidth(
            :class="{ 'is-primary': isSpirituality(2) }",
            @click="setSpirituality(2)"
          ) {{ $t('questionnaire.full_match') }}

    Navigation(backward="/questionnaire/subject", :forward="forward")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'
import { store } from './store'

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      answers: store.answers
    }
  },
  computed: {
    forward() {
      if (this.answers["spirituality"] !== undefined) {
        return "/questionnaire/study"
      } else {
        return ""
      }
    }
  },
  methods: {
    isSpirituality(spirituality) {
      return this.answers["spirituality"] == spirituality
    },
    setSpirituality(spirituality) {
      store.setAnswer('spirituality', spirituality)
    }
  }

}
</script>
