import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import i18n from '@/i18n'

require('@/assets/main.scss');

var app = createApp(App)
app.use(i18n)
app.use(router)
app.mount('#app')

// Hot updates
if (module.hot) {
    module.hot.accept(['./locales/en.json', './locales/de.json'], function () {
      i18n.setLocaleMessage('en', require('./locales/en.json').default)
      i18n.setLocaleMessage('de', require('./locales/de.json').default)
      // Or the following hot updates via $i18n property
      // app.$i18n.setLocaleMessage('en', require('./en').default)
      // app.$i18n.setLocaleMessage('ja', require('./ja').default)
    })
  }