<template lang="pug">
<Navigation />
router-view

<footer class="footer">
  <div class="content has-text-centered">
    <p>
      <strong>StarScign</strong> by <a href="https://geromueller.de">Gero Müller</a>
    </p>
  </div>
</footer>

</template>

<style lang="scss">
</style>

<script>
// @ is an alias to /src
import Navigation from '@/components/Navigation.vue'
import { reactive } from 'vue'

const store = {
    debug: true,
  
    state: reactive({
        questionnaire: {}
    }),
  
    setQuestionnaireElement(key, value) {
      if (this.debug) {
        console.log('setQuestionnaireElementAction triggered with', key, '->', value)
      }
  
      this.$set(this.state.questionnaire, key, value)
    }
}

export default {
  components: {
    Navigation
  },
  data() {
    return {
      state: store.state
    }
  }
}
</script>
