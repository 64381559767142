<template lang="pug">
section.hero
  .hero-body
    .container
      h1.title.has-text-white
        | {{ $t("navigation.questionnaire") }}
router-view
</template>

<style lang="scss">
.hero {
  background: url('~@/assets/start-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
</style>

<script>
import Navigation from './questionnaire/Navigation'

export default {
  components: {
    Navigation,
  },
  data() {
    return {
      answers: {}
    }
  }
}
</script>
<style>
</style>