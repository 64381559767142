{
  "navigation": {
    "start": "Start",
    "questionnaire": "Questionnaire",
    "analysis": "Analysis",
    "about": "About"
  },
  "start": {
    "subtitle": "Starsign meets Science",
    "message": "<h2>Welcome to StarScign</h2><p>Your star sign, or more precisely zodiac sign, determines your personality. So say the astrologers. All nonsense, say the nihilists. Whether you believe in it or not, hasn't the question ever come up whether this striking correlation isn't somehow significant? As a scientist, of course, you can't rule out anything for the time being, since the sign of the zodiac is linked to the month of birth or conception. And whether seasonal environmental influences (e.g. temperature, pollen, sunlight, humidity, viral load) or seasonal social aspects (e.g. exercise, food, clothing, social contacts) have an effect cannot simply be ruled out. However, causality is not the point here. The character traits of the individual signs of the zodiac testify to a close observation of people. One also often hears that the descriptions are so general that one can find oneself in all of them. We can also check that here.</p><p>Besides the personal assessment, we also want to make a general statement. For this purpose, we save all entries and perform a proper statistical analysis with the collected data.</p><p><strong>Have fun with the self-test!</strong></p>",
    "questionnaire": "Start questionnaire!"
  },
  "questionnaire": {
    "backward": "Back",
    "forward": "Next",
    "intro": "<h2>The questionnaire</h2><p>First there are a few personal questions, then there are questions about your character traits. There are positive and negative descriptions, so a total of 24, which are asked in random order. At the end, a summary appears with the results.</p><p><strong>It doesn't matter whether you know the characteristics of the individual signs of the zodiac, please take plenty of time and answer the questions absolutely honestly!</strong></p>",
    "birthday_title": "Birthday",
    "day": "Day",
    "month": "Month",
    "zodiacsign": "Zodiac Sign",
    "optional": "Optional",
    "automatic": "automatic",
    "hour": "Hour",
    "minute": "Minute",
    "year": "Year",
    "place": "Place",
    "sex_title": "Sex",
    "sex_message": "Please provide your sex (gender):",
    "diverse": "diverse",
    "male": "male",
    "female": "female",
    "trait": "Character Trait",
    "trait_matching": "This trait matches:",
    "no_match": "not at all",
    "partly_match": "partly",
    "full_match": "absolutely",
    "results": "Results",
    "sign_matches": "These are your matches with the zodiac signs:",
    "element_matches": "These are your matches with the zodiac elements:",
    "subject_title": "Subject",
    "subject_message": "For whom do you fill out the questionnaire?",
    "subject_self": "For myself",
    "subject_friend": "For someone else",
    "spirituality_title": "Spirituality",
    "spirituality_message": "How open to spirituality is the person you are filling this form out for (you or someone else)?",
    "study_title": "Study",
    "study_message": "The main purpose of this page is a study about the connection between zodiac signs and character traits. For this we need as accurate data as possible and your consent. Have you conscientiously filled out this questionnaire and are willing to provide your information to the study?",
    "study_yes": "Yes, share my information",
    "study_no": "No, do not participate"
  },
  "zodiac_sign": {
    "capricorn": "Capricorn",
    "aquarius": "Aquarius",
    "pisces": "Pisces",
    "aries": "Aries",
    "taurus": "Taurus",
    "gemini": "Gemini",
    "cancer": "Cancer",
    "leo": "Leo",
    "virgo": "Virgo",
    "libra": "Libra",
    "scorpio": "Scorpio",
    "sagittarius": "Sagittarius"
  },
  "zodiac_element": {
    "earth": "Eearth",
    "air": "Air",
    "water": "Water",
    "fire": "Fire"
  },
  "zodiac_sign_traits": {
    "aries_positive": "I am an adventurous person with a lot of energy. Basically, I have an optimistic view of things. I am courageous and don't shy away from challenges of any kind. I like to measure myself against others and thus show my full potential. I am not afraid of setbacks, I accept them and pursue my goal until I achieve it.",
    "aries_negative": "When someone crosses my personal boundary I react very impulsively and aggressively. I tend to become arrogant when I feel unfairly treated. I confront my fellow human beings directly and do not shy away from confrontation. I am ready to fight with all weapons. When I set my mind to something, I am incredibly stubborn and it is not easy to please myself. When things don't go my way, I'm like a little child who doesn't get his way. I am impatient and want everything now and immediately.",
    "taurus_positive": "I am a very loyal person. One of my strengths is reliability, I'm there when there's a need. I am a very sensual person. I like to eat and drink, and I also enjoy physicality in general, including sexuality, with all my senses. I like to care and enjoy the fruits of my labour. I love to see what I nurture thrive. I am an organiser and very responsible.",
    "taurus_negative": "Once I have an opinion of something it is difficult to dissuade me. I am said to have a very stubborn stubbornness. I have a tendency towards materialism and like to spend money on food, clothes and clothing, I am addicted to luxury. I can be very possessive. All in all, I am not very flexible and often find it difficult to empathise with those around me. At times I am quite sluggish and like to temporarily isolate myself from the outside world.",
    "gemini_positive": "There is always something going on with me, I love meeting new people and at parties I am usually the first to arrive and the last to leave. I am a good speaker and storyteller, with me there is never a dull moment. I have a good general knowledge and soak up everything new like a sponge, my mind constantly needs new impulses. I have a strong sense of humour and make sure that the people around me want for nothing and that everyone has fun.",
    "gemini_negative": "I am a chatterbox and no secret is safe with me. When I'm bored, I sometimes tell things that aren't true or start rumours to add a bit of spice to my life. I often feel a bit lost and am very flighty and unstable, I never stay in one place for long, neither physically nor in my mind. When it comes to emotions I often have difficulty expressing them.  I often have mood swings and find it difficult to engage with people on a deep level.",
    "cancer_positive": "I have a strong protective instinct and my maternal/paternal nature sets me apart.  I am gentle by nature and an absolute family man. I nurture and love the most important people in my life devotedly. I am a born empath and always go by my intuition. I am very tender, romantic and also very domestic.",
    "cancer_negative": "I am very vulnerable and feel quickly overrun by my emotions. When that happens, I often feel a kind of paralysis of shock. I can't deal with defeats very well, they often throw me completely off track and then I find it difficult to maintain rationality. I am sometimes very patronising towards my fellow human beings and am quickly offended and withdraw when I feel attacked. I am often moody and may well have a tendency to depression.",
    "leo_positive": "I am a born leader and people are magically attracted to me. I like to be the centre of attention. I always have something to do or am around people, because I can't stand boredom very well. I am very ambitious, in all areas of life. I am very self-confident and also very sensitive and tactful. I like to spend and have great courage towards new things. I love being independent and am basically optimistic about all things. I don't hold grudges and can forgive easily.",
    "leo_negative": "It's better not to provoke me, because it's hard for me to control my impulsive reactions. Once I set my mind on something, I'm no longer interested in what's around it, nor am I interested in other opinions. Even if you are right, I am probably too proud to admit it. I am often perceived as arrogant because of my enormous self-confidence. I have a very dominant nature and love the feeling of control. ",
    "virgo_positive": "I am often found among intellectuals. I value education, order and aesthetics. I am very inquisitive and get to the bottom of things. I have an innate curiosity and want to find out how things work. My mind is analytical and I am an expert at solving problems. Even though I am very successful, I don't brag about it because I am very modest. I am very attentive and always keep an eye on my surroundings. I like to serve people and I am very dutiful, adaptable and reliable. All in all, I am a rather quiet person with conservative tendencies who stays in the background.",
    "virgo_negative": "I can be very pedantic and hypercritical. Also, many describe me as judgmental. I am not spared from this judgement myself, I am more merciless with myself than with anyone else. My perfectionism can mean that I am never satisfied with myself or with anything. In conflicts with fellow human beings, I don't gloss over anything and can come across as very cool and unemotional. I call a spade a spade, with me you know where you stand. At the same time, I always keep calm and when things get hot, I prefer to withdraw.",
    "libra_positive": "I am patient, socially inclined and have a very strong sense of justice. I like harmony and peace. I try hard to make everyone around me happy. I am diplomatically inclined and have a high intellect. We often have a graceful nature and are cheerful and fun-loving companions. I am idealistically inclined, very helpful and self-sacrificing. I have a sense of beauty and aesthetics and also enjoy dressing myself up. ",
    "libra_negative": "I am often driven by a certain recklessness, I have a penchant for luxury and wastefulness, which sometimes makes me overlook inner qualities. Unfortunately, I am easily influenced by others. Since it is important to me to always keep a balance, I may often seem like an ensign in the wind. In order for everyone around me to be happy, I also often throw my own ideas overboard and sacrifice them for the good of the community. Keeping secrets is not my strong point, and I also change my mind quite often. I have difficulty making up my mind, I am never 100% sure of anything. I have a manipulative nature, however, not to harm others, but to serve the greater good.",
    "scorpio_positive": "Passion and loyalty distinguish me, I am capable of deep feelings and fully commit to a cause. People often find me mysterious and inscrutable. I can muster a high level of energy and am very ambitious when I am convinced of something. I am a perfectionist and have the ability to observe everything closely. My intuition is spot on which means that it is almost impossible to deceive me. Secrets are in good hands with me. People feel alive and drawn to me in my presence.",
    "scorpio_negative": "I am very jealous and smell betrayal and infidelity around every corner. I have difficulty trusting people 100%. My tendency to obsess over people or things is strong and often drags me down to its depths. I can be overly possessive and am vengeful when wronged. I never forget, am incredibly resentful and cannot forgive betrayal even after years. The whole or nothing principle stands for me. When it serves me, I can also show a very manipulative side of myself.",
    "sagittarius_positive": "I am a freedom-loving enthusiastic being. I enjoy life itself and seek adventure. I also have a tendency to be philosophical. Versatility stands for me, as well as a great curiosity. I like to discuss God and the world, the latter I also love to travel. Independence is big part of my life, I am myself enough and don't need anyone to feel happy.",
    "sagittarius_negative": "I am very honest and direct, even if it might hurt my counterpart. I can have a very hard brutal way about me, and many perceive me as careless/tactless. I get bored easily and like constant change. I don't handle responsibility very well, whether it's in terms of work or partnership. I'm not particularly good at giving myself up completely. I also find it difficult to stick to one and the same thing, I would rather try something new day after day. I am more than self-confident and consider myself perfect, or at least project that to those around me. ",
    "capricorn_positive": "The bigger the obstacle, the more intense my efforts to overcome it. I don't shy away from hard work and am always focused on the goal. On the way there, I am resourceful and determined. I am very patient and know that success is also a question of time. I sometimes have a sober nature about me that enables me to look at things rationally and from a distance. I was born with a practical disposition. I do things carefully and moderately. Punctuality is very important to me and I have a tendency to be conservative.",
    "capricorn_negative": "I need a lot of time to open up fully to my fellow human beings, if I succeed at all. A bit of mistrust always resonates with me. I always carry the fear of being betrayed inside me. Because of my insecurity, I have mood swings from time to time. Basically, I am a successful person, which is why my emotional side often has to take a back seat or even withers away. The danger is that I revolve around myself and arrogantly and stubbornly follow my line when the need is great to achieve my goal. ",
    "aquarius_positive": "The word 'idealist' is probably the best way to describe me. I want to make the world a better place. I love people and am committed to humanitarian causes. I am a visionary, thinker and inventor with a big dose of intellect. I set new trends and bubble with ideas. Monotony bores me to death. I see the world from different angles and have a good sense of humour, you can steal horses with me and I am a loyal friend. I have a good knack for diplomacy and can mediate well. I am an excellent listener and can express myself very well.",
    "aquarius_negative": "I am unpredictable, even when it seems like everything is going as planned, I can act differently at the last moment. I am a free spirit who doesn't let anyone tell me what to do, especially not in relation to my own life. I was not born to follow, but to change or dissolve existing systems. I am a rebel and a revolutionary. Due to my strongly developed ability to think, I am more in my head than in my heart, which makes me seem distant, cold and non-emotional at times. Emotions never affect my judgement.",
    "pisces_positive": "I am a (day) dreamer and have an incredibly large imagination. I am extremely creative, you can often find me among artists, musicians, etc. . My empathy is boundless and I am a very gentle person capable of deep attachments. I am very selfless, able to give myself fully to others and help where I am needed. I carry a certain humility within me, which is also reflected in my strong feelings. People like me, I have a friendly open-minded nature.",
    "pisces_negative": "I unfortunately tend to give up quickly when something doesn't go according to plan. I have the characteristic to flee from the cold reality into my safe dream worlds. I am often lazy and sluggish when I have to take care of things that don't interest me. I quickly lose my enthusiasm if my creativity is not addressed. I have a tendency to feel sorry for myself, especially when my feelings are hurt. I become over-sensitive and take everything too much to heart. My self-esteem can be easily destroyed because it is not very high by nature. I don't have a strong will, and my enthusiasm can be quickly destroyed by outside influences and pressure from others."
  }
}