<template lang="pug">
section.section
  .container
    h5.title.is-5 {{ $t("questionnaire.birthday_title") }}
    .box
      .columns
        .column
          .field
            label.label {{ $t("questionnaire.day") }}
            .control
              input.input(type="number", placeholder="13", v-model="answers.day")
        .column
          .field
            label.label {{ $t("questionnaire.month") }}
            .control
              input.input(type="number", placeholder="2", v-model="answers.month")
        .column
          .field
            label.label {{ $t("questionnaire.zodiacsign") }}
            .control
              input.input(readonly, :placeholder="$t('questionnaire.automatic')", :value="zodiac_sign")

    h6.title.is-6 {{ $t("questionnaire.optional") }} 
    .box
      .columns
        .column
          .field
            label.label {{ $t("questionnaire.hour") }}
            .control
              input.input(type="number", placeholder="20", v-model="answers.hour")
        .column
          .field
            label.label {{ $t("questionnaire.minute") }}
            .control
              input.input(type="number", placeholder="20", v-model="answers.minute")
        .column
          .field
            label.label {{ $t("questionnaire.year") }}
            .control
              input.input(type="number", placeholder="1980", v-model="answers.year")
        .column
          .field
            label.label {{ $t("questionnaire.place") }}
            .control
              input.input(type="string", placeholder="Berlin")

    Navigation(backward="/questionnaire/sex", :forward="forward")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'
import { store } from './store'
import { zodiac_sign_index, zodiac_sign } from '@/zodiac'

export default {
  components: {
    Navigation,
  },
  created(){
  },
  data() {
    return {
      answers: store.answers
    }
  },
  watch: {
    'answers.day'() {
      if (this.answers.day < 1) {
        this.answers.day = 1
      } else if (this.answers.day > 31) {
        this.answers.day = 31
      }
    },
    'answers.month'() {
      if (this.answers.month < 1) {
        this.answers.month = 1
      } else if (this.answers.month > 12) {
        this.answers.month = 12
      }
    },
    'answers.hour'() {
      if (this.answers.hour < 0) {
        this.answers.hour = 0
      } else if (this.answers.hour > 23) {
        this.answers.hour = 23
      }
    },
    'answers.minute'() {
      if (this.answers.minute < 0) {
        this.answers.minute = 0
      } else if (this.answers.minute > 59) {
        this.answers.minute = 59
      }
    },
    'answers.year'() {
      var d = new Date();
      var n = d.getFullYear();
      if (this.answers.year < 1) {
        this.answers.year = 1
      } else if (this.answers.year > n) {
        this.answers.year = n
      }
    }
  },
  computed: {
    forward() {
      if (this.valid()) {
        return "/questionnaire/trait/1"
      } else {
        return ""
      }
    },
    zodiac_sign(){
      if (!this.valid()) {
        return ""
      }
      return this.$t("zodiac_sign." + zodiac_sign[zodiac_sign_index(this.answers.day, this.answers.month)])
    }
  },
  methods: {
    valid() {
      if (this.answers.day === undefined || this.answers.day < 1 || this.answers.day > 31) {
        return false
      }
      if (this.answers.month === undefined || this.answers.month < 1 || this.answers.month > 12) {
        return false
      }
      return true
    }
  }

}
</script>
