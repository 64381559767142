import { reactive } from 'vue'

export const store = {
  debug: true,

  answers: reactive({
  }),

  setAnswer(key, value) {
    if (this.debug) {
      console.log('setAnswer triggered with', key, '->', value)
    }

    this.answers[key] = value
  },

  async maybePostAnswers() {
    if (this.answers['study'] == true) {
      try {
        const response = await fetch('/apiv1/answers', {
          credentials: 'same-origin',
          method: 'POST',
          body: JSON.stringify(this.answers),
          headers: { 'Content-Type': 'application/json' },
        })
        return await response.json()
      } catch (error) {
        return console.error(error)
      }
    }
  }
}
