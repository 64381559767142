<template lang="pug">
section.section
  .container
    .content(v-html="$t('questionnaire.intro')")

    Navigation(backward="", forward="/questionnaire/sex")

</template>

<style lang="scss">
</style>

<script>
import Navigation from './Navigation'

export default {
  components: {
    Navigation,
  }
}
</script>
