{
  "navigation": {
    "start": "Start",
    "questionnaire": "Fragebogen",
    "analysis": "Auswertung",
    "about": "Über"
  },
  "start": {
    "subtitle": "Sternzeichen trifft Wissenschaft",
    "message": "<h2>Willkommen bei StarScign</h2><p>Dein Sternzeichen, oder genauer Tierkreiszeichen, bestimmt deine Persönlichkeit. Das sagen die Astrologen. Alles Quatsch, sagen die Nihilisten. Egal ob du daran glaubst oder nicht, kam die Frage nicht schonmal auf, ob diese auffällige Korrelation nicht doch irgendwie signifikant ist? Als Wissenschaflter darf man natürlich erstmal nichts ausschließen, ist das Sternzeichen ja mit dem Monat der Geburt bzw. der Empfängnis verbunden. Und ob jahreszeitliche Umwelteinflüße (z.B. Temperatur, Pollen, Sonnelicht, Luftfeuchtigkeit, Virenlast) oder jahreszeitliche soziale Aspekte (z.B. Bewegung, Essen, Kleidung, soziale Kontakte) einen Einfluß haben, kann man nicht so einfach ausschließen. Um eine Kausalität soll es hier aber gar nicht gehen. Die Charaktereigenschaften der einzelenen Tierkreiszeichen zeugen von einer genauen Beobachtung der Menschen. Man hört auch gerne, dass die Beschreibungen so allgmemein sind, dass man sich in allen wiederfinden kann. Auch das können wir hier überprüfen.</p><p>Neben der persönlichen Einschätzung möchten wir auch eine allgemeine Aussage treffen. Dafür speichern wir alle Eingaben und führen mit den gesammelten Daten eine korrekte statistische Analyse durch.</p><p><strong>Viel Spaß beim Selbstest!</strong></p>",
    "questionnaire": "Fragebogen starten"
  },
  "questionnaire": {
    "backward": "Zurück",
    "forward": "Weiter",
    "intro": "<h2>Der Fragebogen</h2><p>Es werden zunächst ein paar persönliche Fragen gestellt, danach kommen dann die Fragen zu deinen Charaktereigenschaften. Es gibt positive un negative Beschreibungen, also insgesamt 24, welche in zufälliger Reihenfolge abgefragt werden. Am Ende erscheint dann eine Zusammenfassung mit den Ergebnissen.</p><p><strong>Egal ob du die Eigenschaften der einzelenen Tierkreiszeichen kennst, nimm dir bitte viel Zeit und beantworte die Fragen absolut ehrlich!</strong></p>",
    "birthday_title": "Geburtstag",
    "day": "Tag",
    "month": "Monat",
    "zodiacsign": "Tierkreiszeichen",
    "optional": "Optional",
    "automatic": "automatisch",
    "hour": "Stunde",
    "minute": "Minute",
    "year": "Jahr",
    "place": "Ort",
    "sex_title": "Geschlecht",
    "sex_message": "Bitte wähle dein Geschlecht:",
    "diverse": "diverse",
    "male": "männlich",
    "female": "weiblich",
    "trait": "Charakctereigenschaft",
    "trait_matching": "Diese Beschreibung passt auf mich:",
    "no_match": "gar nicht",
    "partly_match": "teilweise",
    "full_match": "voll",
    "results": "Ergebnisse",
    "sign_matches": "Hier ist deine Übereinstimmung mit den Sternzeichen:",
    "element_matches": "Hier ist deine Übereinstimmung mit den Elementen:",
    "subject_title": "Proband",
    "subject_message": "Für wen füllst du den Fragebogen aus?",
    "subject_self": "Für mich",
    "subject_friend": "Für jemand anderen",
    "spirituality_title": "Spiritualität",
    "spirituality_message": "Wir offen ist die Person für die du diesen Fragebogen ausfüllst (du oder jemand anderes) für Spiritualität?",
    "study_title": "Studie",
    "study_message": "Das Hauptanliegen dieser Seite ist eine Studie über den Zusammenhang von Sternzeichen und Charaktereigenschaften. Dafür benötigen wir möglichst genaue Daten und die Zustimmung von dir. Hast du diesen Fragebogen gewissenhaft ausgefüllt und bist bereit bist deine Angaben der Studie zur Verfügung zu stellen.",
    "study_yes": "Ja, meine Angaben teilen",
    "study_no": "Nein, nicht teilnehmen"
  },
  "zodiac_sign": {
    "capricorn": "Steinbock",
    "aquarius": "Wassermann",
    "pisces": "Fische",
    "aries": "Widder",
    "taurus": "Stier",
    "gemini": "Zwillinge",
    "cancer": "Krebs",
    "leo": "Löwe",
    "virgo": "Jungfrau",
    "libra": "Waage",
    "scorpio": "Skorpion",
    "sagittarius": "Schütze"
  },
  "zodiac_element": {
    "earth": "Erde",
    "air": "Luft",
    "water": "Wasser",
    "fire": "Feuer"
  },
  "zodiac_sign_traits": {
    "aries_positive": "Ich bin ein abenteuerlustiger Mensch mit viel Energie. Grundsätzlich habe ich eine optimistische Sicht auf die Dinge. Ich bin mutig und scheue mich nicht vor Herausforderungen, egal welcher Art. Ich messe mich gerne mit anderen und zeige so mein volles Potential. Vor Rückschlägen fürchte ich mich nicht, ich nehme sie hin und verfolge so lange mein Ziel, bis ich es erreicht habe.",
    "aries_negative": "Wenn jemand meine persönliche Grenze überschreitet reagiere ich sehr impulsiv und angriffslustig. Ich tendiere dazu, überheblich zu werden, wenn ich mich ungerecht behandelt fühle. Ich konfrontiere meine Mitmenschen direkt und scheue die Auseinandersetzung nicht. Ich bin bereit mit allen Waffen zu kämpfen. Wenn ich mir etwas in den Kopf gesetzt habe bin ich unglaublich stur und es ist nicht leicht, es mir recht zu machen. Wenn es nicht nach meiner Nase geht, bin ich wie ein kleines Kind, das seinen Willen  nicht bekommt. Ich bin ungeduldig und will alles jetzt und sofort.",
    "taurus_positive": "Ich bin ein sehr loyaler Mensch. Eine meiner Stärken liegt in der Zuverlässigkeit, ich bin da, wenn Not am Mann ist. Ich bin ein sehr sinnlicher Mensch. Ich esse und trinke gerne, und auch Körperlichkeit im allgemeinen, inklusive Sexualität koste ich mit allen Sinnen aus. Ich umsorge gerne und habe Freude an den Früchten meiner Arbeit. Ich liebe es zu sehen, wenn das was ich nähre gedeiht. Ich bin ein Organisationstalent  und sehr verantwortungsbewusst.",
    "taurus_negative": "Wenn ich einmal eine Meinung von etwas habe ist es schwierig, mich davon abzubringen. Man sagt mir eine sehr hartnäckige Sturheit nach. Ich habe den Hang zum Materialismus und gebe gerne Geld für Essen, Klamotten und Kleidung aus, ich bin dem Luxus zugetan. Ich kann sehr besitzergreifend sein. Alles in allem bin ich nicht sehr flexibel und habe oftmals Schwierigkeiten, mich in meine Mitmenschen hineinzuversetzen. Bei Zeiten bin ich ziemlich träge und isoliere mich gerne temporär von der Außenwelt.",
    "gemini_positive": "Bei mit mir ist immer was los, ich liebe es neuen Menschen zu begegnen und auf Parties bin ich meist der Erste der kommt und der Letzte der geht. Ich bin ein guter Redner und Geschichtenerzähler, mit mir kommt keine Langeweile auf. Ich habe ein gutes Allgemeinwissen und sauge wie ein Schwamm alles Neue auf, mein Geist braucht ständig neue Impulse. Ich habe einen ausgeprägten Sinn für Humor und sorge dafür, dass es den Menschen um mich herum an nichts fehlt und jeder Spaß hat.",
    "gemini_negative": "Ich bin ein Plappermaul und kein Geheimnis ist bei mir sicher. Wenn ich mich langweile, erzähle ich auch schon mal Dinge, die nicht der Wahrheit entsprechen oder setze Gerüchte in die Welt, um ein bisschen Pfeffer in mein Leben zu bringen. Ich fühle mich oft ein wenig verloren und bin sehr flatterhaft und unbeständig, ich bleibe nie lange an einem Ort, weder physisch, noch im Geist. Wenn es um Emotionen geht habe ich häufig Schwierigkeiten diese auzudrücken.  Ich habe häufig Stimmungsschwankunen und es fällt mir schwer, mich auf Menschen auf tiefer Ebene einzulassen.",
    "cancer_positive": "Ich habe einen ausgeprägten Beschützerinstinkt und meine mütterliche/ väterliche Art zeichnet mich aus.  Ich bin von Natur aus sanft und ein absoluter Familienmensch. Ich nähre und liebe die wichtigsten Menschen in meinem Leben hingebungsvoll. Ich bin der geborene Empath und gehe stets nach meiner Intuition. Ich bin sehr zärtlich, romantisch und zudem sehr häuslich.",
    "cancer_negative": "Ich bin sehr verletzlich und fühle mich schnell von meinen Emotionen überrannt. Wenn das passiert, empfinde ich häufig eine Art Schockstarre. Mit Niederlagen kann ich nicht sonderlich gut umgehen, sie bringen mich oft völlig aus der Bahn und dann fällt es mir schwer, die Rationalität zu bewahren. Ich gebe mich manchmal sehr bevormunden meinen Mitmenschen gegenüber und bin schnell gekränkt und ziehe mich zurück, wenn ich mich angegriffen fühle. Ich bin oft launisch und kann durchaus den Hang zu Depressionen haben.",
    "leo_positive": "Ich bin der geborene Anführer und Menschen fühlen sich von mir wie magisch angezogen. Ich stehe gerne im Mittelpunkt. Ich habe immer etwas zu tun oder bin unter Menschen, denn Langweile kann ich nicht gut aushalten. Ich bin sehr ambitioniert, in allen Bereichen des Lebens. Ich bin sehr selbstbewusst und auch sehr gefühl- und taktvoll. Ich spendiere gerne und habe großen Mut neuen Dingen gegenüber. Ich liebe es, unabhängig zu sein und schaue allen Dingen grundsätzlich optimistisch entgegen. Ich bin nicht nachtragend und kann leicht vergeben.",
    "leo_negative": "Provoziere mich besser nicht, denn es fällt mir schwer meine impulsiven Reaktionen zu kontrollieren. Wenn ich mir einmal etwas in den Kopf gesetzt habe, interessiert mich das drum herum nicht mehr, auch interessieren mich andere Meinungen nicht mehr. Selbst wenn du recht hast, bin ich wahrscheinlich zu stolz, das auch zuzugeben. Oft werde ich aufgrund meines enormen Selbstbewusstseins als arrogant wahrgenommen. Ich habe eine sehr dominante Art und liebe das Gefühl der Kontrolle. ",
    "virgo_positive": "Mich findet man häufig unter Intellektuellen. Ich lege Wert auf Bildung, Ordnung und Ästhetik. Ich bin sehr wissbegierig und gehe den Dingen auf den Grund. Ich habe eine angeborene Neugier und möchte herausfinden, wie die Dinge funktionieren. Mein Geist ist analytisch und im Lösen von Problemen bin ich ein Experte. Auch wenn ich sehr großen Erfolg habe, gebe ich nicht damit an, da ich sehr bescheiden bin. Ich bin sehr aufmerksam und habe stets mein Umfeld im Blick. Ich diene den Menschen gerne und bin sehr pflichtbewusst, anpassungsfähig und zuverlässig. Alles in allem bin ich ein eher ruhiger Mensch mit konservativen Tendenzen, der sich im Hintergrund bewegt.",
    "virgo_negative": "Ich kann sehr pedantisch und überkritisch sein. Auch beschreiben mich viele als verurteilend. Vor diesem Urteil bleibe ich auch selbst nicht verschont, ich bin mit mir selbst erbarmungsloser als mit jedem anderen sonst. Mein Perfektionismus kann dazu führen, dass ich mit mir selbst oder einer Sache niemals zufrieden bin. In Konflikten mit Mitmenschen beschönige ich nichts und kann als sehr kühl und emotionslos rüberkommen. Ich nenne die Dinge beim Namen, bei mir weiß man, woran man ist. Dabei wahre ich stets die Ruhe und wenn es brenzlig wird ziehe ich mich lieber zurück.",
    "libra_positive": "Ich bin geduldig, sozial veranlagt und habe einen sehr ausgeprägten Gerechtigkeitssinn. Ich mag Harmonie und Frieden. Ich gebe mir Mühe, alle um mich herum glücklich zu machen. Ich bin diplomatisch veranlagt und habe einen hohen Intellekt. Wir haben häufig ein anmutiges Wesen und sind heitere und lebenslustige Wegbegleiter. Ich bin idealistisch veranlagt, sehr hilfsbereit und aufopfernd. Ich habe Sinn für Schönheit und Ästhetik und habe auch Spaß daran, mich selbst herauszuputzen. ",
    "libra_negative": "Oft treibt mich ein gewisser Leichtsinn an, ich habe einen Hang zum Luxus und der Verschwendung, welcher mich manchmal innere Qualitäten übersehen lässt. Leider lasse ich mich schnell von anderen beeinflussen. Da es mir wichtig ist, stets die Balance zu wahren, mag ich häufig wie ein Fähnlein im Wind wirken. Damit alle um mich herum glücklich sein können, werfe ich auch häufig meine eigenen Vorstellungen über Bord und opfere diese zum Wohle der Gemeinschaft. Das Bewahren von Geheimnissen ist nicht meine Stärke, außerdem ändere ich recht häufig meine Meinung. Ich habe Schwierigkeiten mich zu entscheiden, ich bin mir nie einer Sache zu 100% sicher. Ich habe eine manipulative Art, jedoch nicht um anderen zu schaden, sondern um dem großen Wohl zu dienen.",
    "scorpio_positive": "Leidenschaft und Loyalität zeichnnen mich aus, ich bin fähig zu tiefen Gefühlen und verschreibe mich einer Sache voll und ganz. Menschen empfinden mich oft als geheimnisvoll und unergründlich. Ich kann ein hohes Level an Energie aufbringen und bin sehr ambitioniert, wenn ich von etwas überzeugt bin. Ich bin ein Perfektionist und habe die Fähigkeit alles genau zu beobachten. Meine Intuition ist auf den Punkt genau was dazu führt, dass es fast unmöglich ist mich zu täuschen. Geheimnisse sind bei mir bestens aufgehoben. Menschen fühlen sich in meiner Gegenwart lebendig und   zu mir hingezogen.",
    "scorpio_negative": "Ich bin sehr eifersüchtig und rieche hinter jeder Ecke Verrat und Untreue. Ich habe Schwierigkeiten damit, den Menschen 100%ig zu vertrauen. Mein Hang zur Obsession gegenüber Menschen oder Dingen ist stark ausgeprägt und reißt mich oftmals mit in seine Tiefen. Ich kann überaus besitzergreifend sein und bin rachsüchtig, wenn mir Unrecht getan wurde. Ich vergesse nie, bin unglaublich nachtragend und kann Betrug auch nach Jahren nicht verzeihen. Das Ganz- oder gar nicht Prinzip steht für mich. Wenn es mir dient, kann ich auch eine sehr manipulative Seite von mir zeigen.",
    "sagittarius_positive": "Ich bin ein freiheitsliebendes enthusiastisches Wesen. Ich habe Spaß am Leben selbst und suche das Abenteuer. Ich habe auch einen Hang dazu, philosophisch zu sein. Vielseitigkeit steht für mich, sowie eine große Neugier. Ich diskutiere gerne über Gott und die Welt, letztere bereise ich auch sehr gerne. Unabhängigkeit ist großer Bestandteil meines Lebens, ich selbst bin mir genug und brauche niemanden, um mich glücklich zu fühlen.",
    "sagittarius_negative": "Ich bin sehr ehrlich und direkt, auch wenn das mein Gegenüber verletzen könnte. Ich kann eine sehr harte brutale Art an mir haben, und viele empfinden mich als sorglos/ taktlos. Ich langweile mich schnell und mag die ständige Veränderung. Mit Verantwortung kann ich nicht sonderlich gut umgehen, egal ob in Bezug auf Arbeit oder Partnerschaft. Sich voll und ganz hinzugeben liegt mir nicht besonders. Bei ein und derselben Sache zu bleiben fällt mir ebenfalls schwer, lieber würde ich Tag für Tag Neues ausprobieren. Ich bin mehr als Selbstbewusst und halte mich selbst für perfekt, oder projiziere das zumindest meinem Umfeld. ",
    "capricorn_positive": "Je größer das Hindernis, desto intensiver meine Bestreben es zu bewältigen. Ich scheue harte Arbeit nicht und bin stets auf das Ziel ausgerichtet. Auf dem Weg dorthin bin ich einfallsreich und  entschlossen. Ich bin sehr geduldig und weiß, dass Erfolg auch eine Frage der Zeit ist. Ich habe zuweilen eine nüchterne Art an mir, die es mir ermöglicht, Dinge rational und aus der Distanz zu betrachten. Praktische Veranlagung wurde mir in die Wiege gelegt. Ich tue Dinge mit Bedacht und maßvoll. Pünktlichkeit ist mir sehr wichtig  und ich habe einen Hang zum Konservativen.",
    "capricorn_negative": "Ich brauche viel Zeit, um mich meinen Mitmenschen voll und ganz zu öffnen, wenn es mir überhaupt gelingt. Ein bisschen Misstrauen schwingt bei mir immer mit. Ich trage immer die Befürchtung in mir betrogen zu werden. Aufgrund meiner Unsicherheit habe ich hin- und wieder Stimmungsschwankungen. Grundsätzlich bin ich ein erfolgreicher Mensch, weswegen meine emotionale Seite oft zurückstecken muss oder sogar verkümmert. Die Gefahr besteht, dass ich mich um mich selbst drehe und arrogant und stur meiner Linie folge, wenn das Bedürfnis danach groß ist, mein Ziel zu erreichen. ",
    "aquarius_positive": "Mit dem Wort „Idealist“ beschreibt man mich wohl am besten. Ich möchte aus der Welt einen besseren Ort machen. Ich liebe die Menschen und bin humanitär engagiert. Ich bin Visionär, Denker und Erfinder mit einer großen Portion Intellekt. Ich setze neue Trends und sprühe vor Ideen. Monotonie langweilt mich zu Tode. Ich sehe die Welt aus verschiedenen Blickwinkeln und habe eine gute Portion Humor, mit mir kann man Pferde stehlen und ich bin ein treuer Freund. Ich habe ein gutes Händchen für Diplomatie und kann gut vermitteln. Ich bin ein ausgezeichneter Zuhörer und kann mich sehr gut ausdrücken.",
    "aquarius_negative": "Ich bin unberechenbar, selbst wenn es so wirkt als liefe alles wie geplant, kann ich im letzten Moment doch anders agieren. Ich bin ein Freigeist, der sich von niemandem etwas vorschreiben lässt, vor allem nicht in Bezug auf mein eigenes Leben. Ich bin nicht dazu geboren um zu folgen, sondern um bestehende Systeme zu verändern oder aufzulösen. Ich bin ein Rebell und Revolutionär. Durch meine stark ausgeprägte Fähigkeit zu denken bin ich mehr im Kopf als im Herzen, was mich zuweilen distanziert, kalt und nicht emotional erscheinen lässt. Emotionen beeinflussen niemals meine Urteilsfähigkeit.",
    "pisces_positive": "Ich bin ein (Tag-) Träumer und habe eine unglaublich große Vorstellungskraft. Ich bin ausgesprochen kreativ, mich findet man oft unter Künstlern, Musikern, usw. . Meine Empathie ist grenzenlos und ich bin ein sehr sanfter Mensch, der zu tiefen Bindungen fähig ist. Ich bin sehr selbstlos, kann mich voll und ganz anderen hingeben und helfe dort, wo ich gebraucht werde. Ich trage eine gewisse Demut in mir, die sich auch in meinen starken Gefühlen wiederspiegelt. Menschen mögen mich, ich habe eine freundliche aufgeschlossene Art.",
    "pisces_negative": "Ich tendiere leider schnell dazu aufzugeben, wenn etwas nicht nach Plan verläuft. Ich habe die Eigenschaft, mich vor der kalten Realität in meine sicheren Traumwelten zu flüchten. Ich bin oft faul und träge wenn ich mich um Dinge kümmern muss, die mich nicht interessieren. Ich verliere schnell meinen Enthusiasmus, wenn meine Kreativität nicht angesprochen wird. Ich habe den Hang zum Selbstmitleid, vor allem wenn meine Gefühle verletzt wurden. Ich werde über-sensitiv und nehme mir alles zu sehr zu Herzen. Meine Selbstachtung kann man leicht zerstören, denn sie ist von Natur aus nicht sehr hoch. Ich habe keinen starken Willen, und mein Enthusiasmus kann durch äußere Einflüsse und Druck durch andere schnell zerstört werden."
  }
}